import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import MainTemplate from 'components/templates/MainTemplate';
import ButtonLink from 'components/atoms/ButtonLink';
import Alert from 'components/atoms/Alert';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import Form from './Form';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const Subtitle = styled.h3`
  margin-top: 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1em;
`;

const Col = styled.div`
  padding: 1em;
  width: 100%;
  flex: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 50%;
  }
`;

const LoginLink = styled(ButtonLink).attrs({
  to: '/signin',
  children: 'Войти',
  neutral: true,
  inline: true,
})``;

export default class SignUpPage extends Component {
  state = {
    success: false,
  };

  handleSuccess = () => {
    this.setState({
      success: true,
    });
  };

  render() {
    const registration = (
      <Row>
        <Col>
          <Form onSuccess={this.handleSuccess} />
        </Col>
        <Col>
          <Subtitle>У Вас уже есть аккаунт?</Subtitle>
          <LoginLink />
        </Col>
      </Row>
    );

    return (
      <MainTemplate>
        <Helmet>
          <title>{title.signup}</title>
          <meta name="keywords" content={metaKeywords.signup} />
          <meta name="description" content={metaDescription.signup} />
        </Helmet>
        <Title>{heading.signup}</Title>
        {this.state.success ? (
          <Alert type="success">
            Регистрация прошла успешно. На Ваш E-mail выслано письмо с дальнейшими инструкциями.
          </Alert>
        ) : (
          registration
        )}
      </MainTemplate>
    );
  }
}
