import { Component } from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import Form from 'components/atoms/Form';
import signupMutation from 'graphql/mutations/signup.graphql';

const ButtonWrapper = styled.div`
  margin-bottom: 1.5em;
`;

export default class extends Component {
  state = {
    values: {
      lastName: '',
      firstName: '',
      middleName: '',
      email: '',
      password: '',
      passwordRepeat: '',
      agreement: false,
    },
    errors: {},
    initialized: false,
  };

  componentDidMount() {
    this.setState({
      initialized: true,
    });
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (target.type === 'checkbox') {
      this.handleFocus(event);
    }

    this.setState((state) => ({
      values: {
        ...state.values,
        [name]: value,
      },
    }));
  };

  handleError = () => {
    window.alert('Произошла ошибка. Попробуйте позднее.');
  };

  handleComplete = ({ signup: { success, errors } }) => {
    if (success) {
      this.props.onSuccess();
      return;
    }

    this.setState({
      errors,
    });
  };

  handleFocus = (event) => {
    const {
      target: { name },
    } = event;

    this.setState((state) => ({
      errors: {
        ...state.errors,
        [name]: null,
      },
    }));
  };

  clearErrors = () => {
    this.setState({
      errors: {},
    });
  };

  render() {
    return (
      <Mutation
        mutation={signupMutation}
        variables={this.state.values}
        onError={this.handleError}
        onCompleted={this.handleComplete}
      >
        {(signup, { loading }) => (
          <Form
            method="post"
            onSubmit={(event) => {
              event.preventDefault();
              this.clearErrors();
              signup();
            }}
          >
            <Form.Control>
              <Form.Label htmlFor="lastName">Фамилия</Form.Label>
              <Form.Input
                type="text"
                id="lastName"
                name="lastName"
                value={this.state.values.lastName}
                onChange={this.handleChange}
                disabled={loading}
                error={!!this.state.errors.lastName}
                onFocus={this.handleFocus}
              />
              <Form.InputErrors errors={this.state.errors.lastName} />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="firstName">
                Имя <Form.Required />
              </Form.Label>
              <Form.Input
                type="text"
                id="firstName"
                name="firstName"
                value={this.state.values.firstName}
                onChange={this.handleChange}
                disabled={loading}
                error={!!this.state.errors.firstName}
                onFocus={this.handleFocus}
                required
              />
              <Form.InputErrors errors={this.state.errors.firstName} />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="middleName">Отчество</Form.Label>
              <Form.Input
                type="text"
                id="middleName"
                name="middleName"
                value={this.state.values.middleName}
                onChange={this.handleChange}
                disabled={loading}
                error={!!this.state.errors.middleName}
                onFocus={this.handleFocus}
              />
              <Form.InputErrors errors={this.state.errors.middleName} />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="email">
                E-mail <Form.Required />
              </Form.Label>
              <Form.Input
                type="email"
                id="email"
                name="email"
                value={this.state.values.email}
                onChange={this.handleChange}
                disabled={loading}
                error={!!this.state.errors.email}
                onFocus={this.handleFocus}
                required
              />
              <Form.InputErrors errors={this.state.errors.email} />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="password">
                Пароль <Form.Required />
              </Form.Label>
              <Form.Input
                type="password"
                id="password"
                name="password"
                value={this.state.values.password}
                onChange={this.handleChange}
                disabled={loading}
                error={!!this.state.errors.password}
                onFocus={this.handleFocus}
                required
              />
              <Form.InputErrors errors={this.state.errors.password} />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="passwordRepeat">
                Повтор пароля <Form.Required />
              </Form.Label>
              <Form.Input
                type="password"
                id="passwordRepeat"
                name="passwordRepeat"
                value={this.state.values.passwordRepeat}
                onChange={this.handleChange}
                disabled={loading}
                error={!!this.state.errors.passwordRepeat}
                onFocus={this.handleFocus}
                required
              />
              <Form.InputErrors errors={this.state.errors.passwordRepeat} />
            </Form.Control>
            <Form.Control>
              <Form.CheckboxLabel>
                <Form.Checkbox
                  name="agreement"
                  checked={this.state.values.agreement}
                  onChange={this.handleChange}
                  disabled={loading}
                  required
                />
                С{' '}
                <Link href="/privacy-policy" target="_blank">
                  политикой конфиденциальности
                </Link>{' '}
                согласен
              </Form.CheckboxLabel>
              <Form.InputErrors errors={this.state.errors.agreement} />
            </Form.Control>
            <ButtonWrapper>
              <Button type="submit" primary disabled={loading || !this.state.initialized}>
                {loading || !this.state.initialized ? 'Загрузка...' : 'Зарегистрироваться'}
              </Button>
            </ButtonWrapper>
            <p>
              Поля, отмеченные <Form.Required />, обязательны для заполнения
            </p>
          </Form>
        )}
      </Mutation>
    );
  }
}
